import * as React from "react";

// styles
const pageStyles = {
  color: "#232129",
  paddingTop: "96px",
  paddingBottom: "96px",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingBottom: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  marginTop: 0,
  marginRight: "auto",
  marginBottom: 0,
  marginLeft: "auto",
  maxWidth: "900px",
};

const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
};

const paragraphStyles = {
  marginBottom: 48,
  fontSize: "25px",
  lineHeight: "32px",
  textAlign: "justify",
};

const TermsOfService = () => {
  return (
    <main style={pageStyles}>
      <title>Términos y condiciones</title>
      <h1 style={headingStyles}>Términos y condiciones</h1>
      <p style={paragraphStyles}>
        Al descargar o usar la aplicación, estos términos se aplicarán
        automáticamente a usted; por lo tanto, debe asegurarse de leerlos
        detenidamente antes de usar la aplicación. No se le permite copiar ni
        modificar la aplicación, ninguna parte de la aplicación o nuestras
        marcas comerciales de ninguna manera. No se le permite intentar extraer
        el código fuente de la aplicación y tampoco debe intentar traducir la
        aplicación a otros idiomas o hacer versiones derivadas. La aplicación en
        sí, y todas las marcas comerciales, derechos de autor, derechos de bases
        de datos y otros derechos de propiedad intelectual relacionados con
        ella, aún pertenecen a Dotpem. Dotpem se compromete a garantizar que la
        aplicación sea lo más útil y eficiente posible. Por esa razón, nos
        reservamos el derecho de realizar cambios en la aplicación o cobrar por
        sus servicios, en cualquier momento y por cualquier motivo. Nunca le
        cobraremos por la aplicación o sus servicios sin dejarle muy claro
        exactamente por qué está pagando. La aplicación Mercadia almacena y
        procesa los datos personales que nos ha proporcionado para proporcionar
        nuestro Servicio. Es su responsabilidad mantener su teléfono y el acceso
        a la aplicación seguros. Por lo tanto, le recomendamos que no haga
        jailbreak ni rootee su teléfono, que es el proceso de eliminar las
        restricciones y limitaciones de software impuestas por el sistema
        operativo oficial de su dispositivo. Podría hacer que su teléfono sea
        vulnerable a malware / virus / programas maliciosos, comprometer las
        funciones de seguridad de su teléfono y podría significar que la
        aplicación Mercadia no funcionará correctamente o no funcionará en
        absoluto. La aplicación utiliza servicios de terceros que declaran sus
        propios términos y condiciones. Enlace a los términos y condiciones de
        los proveedores de servicios externos utilizados por la aplicación
      </p>
      <p style={paragraphStyles}>
        - Servicios de Google Play - Google Analytics para Firebase - Firebase
        Crashlytics
      </p>
      <p style={paragraphStyles}>
        Debe tener en cuenta que hay ciertas cosas de las que Dotpem no se
        responsabiliza. Ciertas funciones de la aplicación requerirán que la
        aplicación tenga una conexión a Internet activa. La conexión puede ser
        Wi-Fi o provista por su proveedor de red móvil, pero Dotpem no puede
        asumir la responsabilidad de que la aplicación no funcione con todas sus
        funciones si no tiene acceso a Wi-Fi y no le queda nada de su asignación
        de datos. Si está utilizando la aplicación fuera de un área con Wi-Fi,
        debe recordar que los términos del acuerdo con su proveedor de red móvil
        se seguirán aplicando. Como resultado, su proveedor de telefonía móvil
        puede cobrarle el costo de los datos durante la duración de la conexión
        mientras accede a la aplicación, u otros cargos de terceros. Al usar la
        aplicación, acepta la responsabilidad de dichos cargos, incluidos los
        cargos de datos de roaming si usa la aplicación fuera de su territorio
        de origen (es decir, región o país) sin desactivar el roaming de datos.
        Si no es el pagador de facturas del dispositivo en el que está
        utilizando la aplicación, En la misma línea, Dotpem no siempre puede
        asumir la responsabilidad de la forma en que usa la aplicación, es
        decir, debe asegurarse de que su dispositivo permanezca cargado; si se
        queda sin batería y no puede encenderlo para utilizar el Servicio,
        Dotpem no puede aceptar la responsabilidad. Con respecto a la
        responsabilidad de Dotpem por su uso de la aplicación, cuando esté
        usando la aplicación, es importante tener en cuenta que, aunque nos
        esforzamos por asegurarnos de que esté actualizado y sea correcto en
        todo momento, confiamos en terceros para proporcionar información para
        que podamos ponerla a su disposición. Dotpem no acepta ninguna
        responsabilidad por cualquier pérdida, directa o indirecta, que
        experimente como resultado de confiar completamente en esta
        funcionalidad de la aplicación. En algún momento, es posible que
        deseemos actualizar la aplicación. La aplicación está disponible
        actualmente en Android: los requisitos del sistema (y para cualquier
        sistema adicional al que decidamos extender la disponibilidad de la
        aplicación) pueden cambiar, y deberá descargar las actualizaciones si
        desea seguir usando la aplicación. Dotpem no promete que siempre
        actualizará la aplicación para que sea relevante para usted y / o
        funcione con la versión de Android que ha instalado en su dispositivo.
        Sin embargo, promete aceptar siempre las actualizaciones de la
        aplicación cuando se le ofrezcan. También es posible que deseemos dejar
        de proporcionar la aplicación. y puede terminar su uso en cualquier
        momento sin notificarle la terminación. A menos que le indiquemos lo
        contrario, tras cualquier rescisión, (a) los derechos y licencias
        otorgados a usted en estos términos terminarán; (b) debe dejar de usar
        la aplicación y (si es necesario) eliminarla de su dispositivo.
      </p>
      <h2>Cambios a estos términos y condiciones</h2>
      <p style={paragraphStyles}>
        Es posible que actualicemos nuestros Términos y condiciones de vez en
        cuando. Por lo tanto, le recomendamos que revise esta página
        periódicamente para ver si hay cambios. Le notificaremos de cualquier
        cambio publicando los nuevos Términos y Condiciones en esta página.
        Estos términos y condiciones entran en vigencia a partir del 2021-03-01.
      </p>
      <h2>Contáctenos</h2>
      <p style={paragraphStyles}>
        Si tiene alguna pregunta o sugerencia sobre nuestros Términos y
        condiciones, no dude en contactarnos en support@mercadia.app.
      </p>
    </main>
  );
};

export default TermsOfService;
